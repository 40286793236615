import React, { useState, useEffect, useRef } from "react"
import {
  Container,
  Row,
  Spinner,
  DropdownButton,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap"
import { Link } from "gatsby"

import PostCard from "./post-card"
import PostCardNew from "./post-card-2"
import Pagination from "./pagination"

const PostList = (props) => {
  const mobileDeviceSize = 480
  const posts = props.posts
  const categories = props.categories
  const [catName, setCatName] = useState("All")

  const [currentItems, setCurrentItems] = useState(null)
  const [currentItemsMobile, setCurrentItemsMobile] = useState(null)
  const [pageCount, setPageCount] = useState([1])
  const [currentPage, setCurrentPage] = useState(1)
  const [allPosts, setAllPosts] = useState(null)
  const [itemOffset, setItemOffset] = useState(0)
  const [isCurrPageReset, setIsCurrPageReset] = useState(false)

  const pageWidth = typeof window !== "undefined" && window.innerWidth
  const [isResizeListenerEnabled, setIsResizeListenerEnabled] = useState(false)
  const [currWidth, setCurrWidth] = useState(0)
  const [isMobileDevice, setIsMobileDevice] = useState(pageWidth <= 480)

  const [isVisible, setIsVisible] = useState(false)
  const [postCount, setPostCount] = useState(0)
  const containerRef = useRef(null)
  const afterBlogsSec =
    typeof document !== "undefined"
      ? document.querySelectorAll(
          ".techblog-section, #quotes, .site-footer, #copyrights"
        )
      : []
  const getFilteredPost = (data) => {
    return data.filter((data) => {
      if (catName === "All") return data
      return data.node.categories.some((dt) => dt.title === catName)
    })
  }
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
    delay: 400,
  }
  const cbObserver = async (entries) => {
    const [entry] = entries
    const currPosts = getFilteredPost(posts)
    const count = postCount + 1
    const sliceAllItems = currPosts.slice(0, count)
    ;[].forEach.call(afterBlogsSec, function (el) {
      el.classList.remove("d-none")
    })
    if (count <= currPosts.length) {
      ;[].forEach.call(afterBlogsSec, function (el) {
        el.classList.add("d-none")
      })
    }
    if (currPosts.length < count) return
    setIsVisible(entry.isIntersecting)
    setAllPosts(currPosts)
    setCurrentItemsMobile(sliceAllItems)
    if (isVisible) {
      setPostCount(count)
      setIsVisible(false)
    }
  }

  const scrollToPostListRef = useRef(null)
  // Invoke when user click to request another page.
  const handlePageClick = (page) => {
    const newOffset = ((page - 1) * 9) % allPosts.length
    console.log(
      `User requested page number ${page}, which is offset ${newOffset}`
    )
    setItemOffset(newOffset)
    setCurrentPage(page)
    setIsCurrPageReset(false)
  }

  const handleWidthResize = (width) => {
    if (width <= mobileDeviceSize) {
      setIsMobileDevice(true)
    } else {
      setIsMobileDevice(false)
    }
    setCurrWidth(width)
  }

  useEffect(() => {
    const currPosts = getFilteredPost(posts)

    if (!isResizeListenerEnabled) {
      setIsResizeListenerEnabled(true)
      if (typeof window !== "undefined") {
        window.addEventListener("resize", () =>
          handleWidthResize(window.innerWidth)
        )
      }
    }

    if (pageWidth > mobileDeviceSize) {
      setIsVisible(false)
      ;[].forEach.call(afterBlogsSec, function (el) {
        el.classList.remove("d-none")
      })

      const offSet = currentPage === 1 ? 0 : isCurrPageReset ? 0 : itemOffset
      const endOffset = offSet + 10
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`)
      setAllPosts(currPosts)
      setCurrentItems(currPosts.slice(offSet, endOffset))
      setPageCount(() => {
        const count = Math.ceil(currPosts.length / 9)
        const pages = []
        for (var i = 1; i <= count; i++) {
          pages.push(i)
        }
        return pages
      })
      setItemOffset(endOffset)
      if (isCurrPageReset) setCurrentPage(1)
    }

    if (
      typeof window !== "undefined" &&
      window.innerWidth <= mobileDeviceSize
    ) {
      const count = postCount + 1
      const observer = new IntersectionObserver(cbObserver, options)
      const unobserveRef = () => {
        if (containerRef.current) observer.unobserve(containerRef.current)
      }

      ;[].forEach.call(afterBlogsSec, function (el) {
        el.classList.add("d-none")
      })

      if (containerRef.current) observer.observe(containerRef.current)

      if (currPosts.length < count) return () => unobserveRef()
      setAllPosts(currPosts)
      setCurrentItemsMobile(currPosts.slice(0, count))
      if (isVisible) {
        setPostCount(count)
        setIsVisible(false)
      }

      return () => unobserveRef()
    }
  }, [currentPage, catName, containerRef, isVisible, postCount, currWidth])

  return (
    <>
      {pageWidth > mobileDeviceSize ? (
        <ul className="mb-5 nav nav-tabs nav-fill press-tabs box-shadow">
          <li className="d-inline nav-item nav-link py-3">
            <a
              className="nav-item nav-link"
              onClick={() => {
                setCatName("All")
                setIsCurrPageReset(true)
                setPostCount(0)
                scrollToPostListRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }}
            >
              All
            </a>
          </li>
          {categories
            .filter((cat) => cat.featured[0].categories[0].featured)
            .map((tag, i) => (
              <li className="d-inline nav-item nav-link py-3">
                <a
                  onClick={() => {
                    setCatName(tag.fieldValue)
                    setIsCurrPageReset(true)
                    setPostCount(0)
                    scrollToPostListRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    })
                  }}
                >
                  {tag.fieldValue}
                </a>
              </li>
            ))}
          <li className="d-inline dropdown nav-item nav-link py-3">
            <DropdownButton
              title="More Topics"
              className="more-list-dropdown custom-button-style"
            >
              <div
                className="d-flex flex-column"
                style={{ width: "max-content" }}
              >
                <ul class="list-unstyled box-shadow">
                  {categories
                    .filter((cat) => !cat.featured[0].categories[0].featured)
                    .map((tag, i) => (
                      <li class="d-inline">
                        <Dropdown.Item>
                          <a
                            className="d-inline nav-item nav-link py-3"
                            onClick={() => {
                              setCatName(tag.fieldValue)
                              setIsCurrPageReset(true)
                              setPostCount(0)
                              scrollToPostListRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                              })
                            }}
                          >
                            {tag.fieldValue}
                          </a>
                        </Dropdown.Item>
                      </li>
                    ))}
                </ul>
              </div>
            </DropdownButton>
          </li>
        </ul>
      ) : (
        ""
      )}
      <Container style={{ paddingBottom: "6rem" }}>
        <Row className="justify-content-center" ref={scrollToPostListRef}>
          {currentItems &&
            currentItems.slice(0, 1).map(({ node }) => {
              return <PostCardNew {...node} />
            })}
        </Row>
        <Row ref={scrollToPostListRef}>
          {!currentItems
            ? ""
            : isMobileDevice
            ? ""
            : currentItems.slice(1).map(({ node }) => {
                return <PostCard {...node} />
              })}
          {!currentItemsMobile
            ? ""
            : !isMobileDevice
            ? ""
            : currentItemsMobile.map(({ node, i }) => {
                return <PostCard {...node} />
              })}
        </Row>
        {pageWidth <= 480
          ? ""
          : currentItems && (
              <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
              />
            )}
      </Container>
      <div ref={containerRef} className="d-flex justify-content-center mb-3">
        {pageWidth <= 480 &&
        currentItemsMobile &&
        currentItemsMobile.length > postCount ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          ""
        )}
      </div>
    </>
  )
}

export default PostList
