import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import PageHeading from "../components/heading/page-heading"

// import BlogSidebar from "../components/blogSidebar/sidebar"
import PostList from "../components/blog-list/post-list"
import HeaderBg from "../images/homepage-header-background.jpg"

// images
// import HeadingBg from "../images/slide3.jpg"

import { graphql } from "gatsby"
const BlogsTemplate = (props) => {
  const { data } = props
  // const siteTitle = data.site.siteMetadata.title
  const posts = data.allSanityPost.edges
  const categories = data.allSanityPost.group
  return (
    <Layout>
      <SEO
        title={"Bay Street Blog"}
        description={
          "Bay Street Capital Holdings is an independent investment advisory, wealth management, and financial planning firm headquartered in Palo Alto, CA. We manage portfolios with the goal of maintaining and increasing total assets and income.  Although many financial advisors may focus on maximizing returns, we place a higher priority on managing total risk and volatility."
        }
        keyword={""}
      />
      <div className="page-header">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xl-10">
              <div className="header-text">
                <h1 className="text-black mb-4">Our Insights</h1>
                <p className="lead">
                  We share our insights on all things finance from investing,
                  real estate, and investment opportunities to financial market
                  updates, wealth management, personal finance, and much more.
                  Financial literacy empowers better decisions which, in turn,
                  allows you to make informative decisions. We invite you to
                  enlighten your mind to the various ways in which you can
                  improve your financial literacy on your journey to financial
                  freedom.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="header-bg"
          style={{ backgroundImage: `url(${HeaderBg})` }}
        />
      </div>

      <div id="container-template" className="container blogs-template">
        <div className="articles-list-wrappers articles-list-wrapper">
          <div className="container">
            <div className="border-top row py-110">
              <div className="col-12">
                <PostList posts={posts} categories={categories} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogsTemplate

export const BlogsQuery = graphql`
  query BlogsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allSanityPost(sort: { fields: [publishedAt, _createdAt], order: DESC }) {
      edges {
        node {
          id
          title
          excerpt
          _createdAt
          publishedAt
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          authors {
            name
            image {
              asset {
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  sizes: "40"
                  layout: CONSTRAINED
                )
              }
            }
          }
          slug {
            current
          }
          body
          categories {
            featured
            title
          }
        }
      }
      group(field: categories___title) {
        fieldValue
        featured: nodes {
          categories {
            featured
          }
        }
        edges {
          node {
            title
            authors {
              name
              image {
                asset {
                  gatsbyImageData(
                    fit: FILL
                    placeholder: BLURRED
                    sizes: "40"
                    layout: CONSTRAINED
                  )
                }
              }
            }
            publishedAt
            categories {
              featured
              title
            }
            slug {
              current
            }
            mainImage {
              asset {
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  sizes: ""
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`
