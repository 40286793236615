import React from "react"
import { Link } from "gatsby"

const Pagination = (props) => {
  const { currentPage, pageCount, handlePageClick } = props
  return (
    <div className="d-flex justify-content-center align-items-center mt-5 nwslst">
      <ul className="list-unstyled d-flex mb-0 pagination-number text-truncate">
        {currentPage > 1 && pageCount.length > 1 ? (
          <li>
            <Link
              aria-label="link"
              to={`/blog/`}
              onClick={() => handlePageClick(currentPage - 1)}
            >
              <i className="fa fa-angle-left paginate-arrow"></i>
            </Link>
          </li>
        ) : (
          ""
        )}
        {pageCount.map((page) => {
          if (
            currentPage == page ||
            page == currentPage - 1 ||
            page == currentPage + 1
          ) {
            return (
              <li>
                <Link
                  activeclassname="current"
                  className={page == currentPage ? "middlePaginate" : ""}
                  aria-label="link"
                  to={`/blog/`}
                  onClick={() => handlePageClick(page)}
                >
                  {page}
                </Link>
              </li>
            )
          }
        })}
        {!pageCount.length ||
        currentPage == pageCount.length ||
        pageCount.length <= 1 ? (
          ""
        ) : (
          <li>
            <Link
              aria-label="link"
              to={`/blog/`}
              onClick={() => handlePageClick(currentPage + 1)}
            >
              <i className="fa fa-angle-right paginate-arrow"></i>
            </Link>
          </li>
        )}
      </ul>
    </div>
  )
}

export default Pagination
