import React from "react"
import { Col } from "react-bootstrap"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import placeholder from "../../images/place-holder.webp"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Moment from "react-moment"
import person from "../../images/adult-beard-blurred-background-936072.jpg"

const slugify = require("slugify")

const PostCardNew = (node) => {
  return (
    <Col md={6} lg={4} className={`mb-4`}>
      <LazyLoadComponent>
        <div className="grid-item-inner shadowed h-100 blog-item">
          <div className="blog-item-featured">
            <a aria-label="link" href={`/${node.slug.current}/`}>
              {node.mainImage !== null ? (
                <GatsbyImage
                  image={node.mainImage.asset.gatsbyImageData}
                  imgClassName="img-fluid"
                  alt={node.name}
                  style={{ height: "13rem" }}
                  layout="constrained"
                />
              ) : (
                <div className="article-banner-wrapper">
                  <div
                    className="article-banner"
                    style={{
                      backgroundImage: `url(${placeholder})`,
                    }}
                  />
                </div>
              )}
            </a>
          </div>
          <div className="blog-text py-5 px-4">
            <ul className="blog-category d-flex flex-wrap list-unstyled">
              {node.categories.map((category, idx) => {
                return (
                  <li className="mx-0">
                    <Link
                      to={`/category/${slugify(category.title.toLowerCase())}/`}
                      className="article-category"
                    >
                      {(idx ? ", " : "") + category.title}
                    </Link>
                  </li>
                )
              })}
            </ul>

            <h3 className="article-title-main">
              <Link
                className="text-dark"
                style={{ boxShadow: `none` }}
                to={`/${slugify(
                  node.slug.current.replace("'", "").toLowerCase()
                )}/`}
                title={node.title}
              >
                {node.title}
              </Link>
            </h3>
            <p>{node.excerpt?.substring(0, 70)}...</p>
            <div className="article-date mb-4">
              <Moment add={{ days: 1, hours: 9 }} format="MMMM DD, YYYY">
                {node.publishedAt || node._createdAt}
              </Moment>
            </div>
            <div className="blog-item-info row align-items-center">
              <div className="col-xl-3 col-lg-4 col-md-5 col-3 pr-lg-0 pr-md-0 pr-0 align-self-center">
                <div className="blog-item-author-image">
                  <GatsbyImage
                    placeholder="blurred"
                    className="img-fluid"
                    imgClassName="img-fluid"
                    image={
                      node.authors !== null
                        ? node.authors.image.asset.gatsbyImageData
                        : person
                    }
                    alt={node.authors ? node.authors.name : "Dev Team"}
                    imgStyle={{ objectFit: "contain", borderRadius: "100%" }}
                  />
                </div>
              </div>
              <div className="col-xl-9 col-lg-8 col-md-7 col-9 ml-lg-0 pl-lg-0 pl-md-0 pl-0">
                <div className="article-author">
                  {node.author !== null ? (
                    <div className="article-author d-inline-block">
                      <Link
                        to={`/author/${slugify(
                          node.authors.name.toLowerCase()
                        )}/`}
                        data-wpel-link="internal"
                      >
                        {node.authors.name}
                      </Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>
    </Col>
  )
}

export default PostCardNew
